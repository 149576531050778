import { Spinner } from "react-bootstrap";
export default function Heading({ title = [], showLoader = false, children }) {
  
  return (
    <div className="card mb-1 border-0">
      <div className=" card-header d-flex py-2 align-content-center  justify-content-between">
        <div className="h4">{title}</div>
        <div className="float-end">{children}</div>
      </div>
      {showLoader && <div className="bg-loader"><Spinner size="lg" title="Loading..." /></div>}
      
    </div>
  )
}