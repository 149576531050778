import axiosInstance from "../lib/axiosInstance";

class widgetModel {

    constructor() {
        this.base_url = "/widgets"
    }
    async userTotal(query = []) {
       
        return (await axiosInstance.get(`${this.base_url}/users/total?${new URLSearchParams(query)}`)).data;
    }
    
       

}

export default new widgetModel();